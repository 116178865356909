<template>
    <section class="inside-form h-100">
        <div class="row mx-0 mb-4 mt-3">
            <div class="col-12 text-general text-center f-600" style="font-size:37px;">
                Iniciar sesión
                <p v-if="$route.name == 'login.form.admin'" class="text-general f-400 text-center f-13">
                    Administrador
                </p>
                <p v-if="$route.name == 'login.form.leechero'" class="text-general f-400 text-center f-13">
                    {{ $config.vendedor }}
                </p>
            </div>
        </div>
        <div v-if="$route.name == 'login.form.cliente' || $route.name == 'login.form.leechero'" class="row mx-0">
            <div class="col-12 d-middle-center py-3">
                <!-- <img src="/img/auth/facebook.svg" style="max-width:100%;height:44px;" class="obj-cover mx-3 cr-pointer" @click="iniciarSesionFacebook" /> -->
                <img src="/img/auth/google.svg" style="max-width:100%;height:44px;" class="obj-cover mx-3 cr-pointer" @click="iniciarSesionGoogle" />
                <img src="/img/icons/smartphone.png" style="max-width:100%;height:44px;" class="obj-cover mx-3 cr-pointer" @click="$router.push({name: 'login.codigo', params: {tipoIngreso: 1, tipoLogin}})" />
                <!-- <img src="/img/icons/email.png" style="max-width:100%;height:44px;" class="obj-cover mx-3" @click="$router.push({name: 'login.codigo', params: {tipoIngreso: 2, tipoLogin}})" /> -->
            </div>
        </div>
        <div v-if="$route.name == 'login.form.cliente' || $route.name == 'login.form.leechero'" class="row mx-0 align-items-center px-3 mb-3">
            <div class="col">
                <hr class="border" />
            </div>
            o
            <div class="col">
                <hr class="border" />
            </div>
        </div>
        <div class="form-group d-middle-center mb-3">
            <div class="position-relative" style="width:337px;">
                <div v-if="existeErrorSesionExiste" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                    <p class="text-general-red f-30 f-600 mt-3 text-center">
                        ¿Seguro eres tú?
                    </p>
                    <p class="text-general f-15 mt-1 f-500 text-center">
                        No logro recordarte, asegúrate de <br /> escribir bien tu usuario.
                    </p>
                </div>
                <div v-else-if="existeErrorSesionTipoAdmin" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                    <p class="text-general-red f-30 f-600 mt-3 text-center">
                        Espera
                    </p>
                    <p class="text-general f-15 mt-1 f-500 text-center">
                        Estás intentando ingresar como un administrador.
                    </p>
                    <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.admin'})">
                        Ir a la aplicación de administrador
                    </p>
                </div>
                <div v-else-if="existeErrorSesionTipoLeechero" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                    <p class="text-general-red f-30 f-600 mt-3 text-center">
                        Espera
                    </p>
                    <p class="text-general f-15 mt-1 f-500 text-center">
                        Estás intentando ingresar como un {{ $config.vendedor }}.
                    </p>
                    <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.leechero', params: { ingresoLeechero: false }})">
                        Ir a la aplicación de {{ $config.vendedor }}
                    </p>
                </div>
                <div v-else-if="existeErrorSesionTipoCliente" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                    <p class="text-general-red f-30 f-600 mt-3 text-center">
                        Espera
                    </p>
                    <p class="text-general f-15 mt-1 f-500 text-center">
                        Estás intentando ingresar como un cliente.
                    </p>
                    <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.cliente'})">
                        Ir a la aplicación de cliente
                    </p>
                </div>
                <el-input
                v-model="payload.usuario"
                size="small"
                :disabled="cargando"
                placeholder="Correo electrónico o celular"
                :class="`w-100 br-6 ${existeErrorSesionExiste ? 'login-warning' : ''}`"
                maxlength="70"
                @keyup.enter.native="iniciarSesion"
                />
            </div>
        </div>
        <div class="form-group d-middle-center mb-3">
            <div class="position-relative" style="width:337px;">
                <div v-if="existeErrorSesionContrasena" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                    <p class="text-general-red f-30 f-600 mt-3 text-center">
                        ¡Oooops!
                    </p>
                    <p class="text-general f-15 mt-1 f-500 text-center">
                        Parece que has ingresado mal tu <br /> contraseña, vuelve a intentarlo.
                    </p>
                </div>
                <el-input
                v-model="payload.contrasena"
                :disabled="cargando"
                size="small"
                placeholder="Ingresa la contraseña"
                :class="`w-100 br-6 ${existeErrorSesionContrasena ? 'login-warning' : ''}`"
                maxlength="150"
                show-password
                @keyup.enter.native="iniciarSesion"
                />
            </div>
        </div>
        <div v-if="$route.name != 'login.form.admin'" class="row mx-0 justify-content-end px-4 mb-4" @click="abrirModalRecuperarContrasena">
            <div class="col-auto f-15 text-general cr-pointer">
                Recuperar contraseña
            </div>
        </div>
        <div class="row mx-0 justify-center my-2">
            <el-button
            v-loading="cargando"
            class="btn f-14 bg-general text-white shadow br-8 btn-block text-center p-0"
            style="width:337px;height:36px;"
            @click="iniciarSesion"
            >
                Ingresar
            </el-button>
        </div>
        <div v-if="$route.name == 'login.form.admin'" class="row mx-0 text-general justify-center mt-3">
            <span class="f-500 mr-2">¿No eres Administrador?</span> ingresar como:
            <div class="col-12 text-center">
                <span class="text-green f-15 cr-pointer" @click="$router.push({name: 'ingreso.vendedor'})">{{ $config.vendedor }}</span> 
                <!-- o -->
                <!-- <span class="text-green f-15 cr-pointer" @click="$router.push({name: 'login.form.cliente'})">{{$config.cliente}}</span> -->
            </div>
        </div>
        <div v-if="$route.name == 'login.form.cliente'" class="row mx-0 my-3 mt-4 justify-center">
            <div class="col-auto px-2 text-general">
                ¿Aun no te registraste?
            </div>
            <div class="col-auto px-1 cr-pointer" style="color:#28D07B;">
                Registrarme aquí
            </div>
        </div>
        <recuperarContrasena ref="RecuperarContrasena" :usuario="payload.usuario" :tipo-login="tipoLogin" />
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import firebase from '~/library/firebase';

const VUEX_RUTA = 'auth'

export default {
    components: {
        recuperarContrasena: () => import('~/pages/auth/partials/recuperarContrasena.vue')
    },
    data(){
        return {
            payload: {
                usuario: '',
                contrasena: '',
                tipo: 1
            },
            contrasenaVisible: false,
            cargando: false,
            erroresSesion: ['U001', 'UT001', 'UT002', 'UT003', 'C001'],
            estadoPermitidos: [4, 5],
            errores: {
                sesion: ['usuario', 'contrasena'],
                general: ['proyecto', 'tipo']
            },
            tipo: 1
        }
    },
    computed: {
        ...mapGetters({
            error: `${VUEX_RUTA}/obtenerError`,
        }),
        tipoLogin(){
            let tipo = 1
            switch (this.$route.name){
            case 'login.form.admin':
                tipo = 1
                break;
            case 'login.form.leechero':
                tipo = 2
                break;
            case 'login.form.cliente':
                tipo = 5
                break;
            default:
                break;
            }
            return tipo
        },
        existenErrores(){
            return !_.isEmpty(this.error)
        },
        existeErrorSesion(){
            return this.existenErrores && this.erroresSesion.includes(this.error.codigo)
        },
        existeErrorSesionTipoLeechero(){
            return this.existenErrores && this.erroresSesion[2].includes(this.error.codigo)
        },
        existeErrorSesionTipoCliente(){
            return this.existenErrores && this.erroresSesion[3].includes(this.error.codigo)
        },
        existeErrorSesionTipoAdmin(){
            return this.existenErrores && this.erroresSesion[1].includes(this.error.codigo)
        },
        existeErrorSesionExiste(){
            return this.existenErrores && this.erroresSesion[0].includes(this.error.codigo)
        },
        existeErrorSesionContrasena(){
            return this.existenErrores && this.erroresSesion[4].includes(this.error.codigo)
        },
        existeErrorServidor(){
            return this.existenErrores && this.error.tipo === 500
        }
    },
    watch: {
        'payload.usuario'(){
            this.removerErrores();
        },
        'payload.contrasena'(){
            this.removerErrores();
        },
        '$route.name'(){
            this.removerErrores();
        }
    },
    created(){
        this.removerErrores();
    },
    methods: {
        abrirModalRecuperarContrasena(){
            this.$router.push({name: 'recuperar-contrasena', params: { usuario:this.payload.usuario, tipoLogin: this.tipoLogin }})
        },
        ...mapActions({
            intentarIniciarSesion: `${VUEX_RUTA}/intentarIniciarSesion`,
            intentarIniciarSesionSocial: `${VUEX_RUTA}/intentarIniciarSesionSocial`
        }),
        ...mapMutations({
            almacenarError: `${VUEX_RUTA}/ALMACENAR_ERROR`,
            removerErrores: `${VUEX_RUTA}/REMOVER_ERRORES`,
        }),
        alternarCargando(){
            this.cargando = !this.cargando
        },
        iniciarSesion(){
            this.payload.tipo = this.tipoLogin
            this.alternarCargando()
            this.intentarIniciarSesion(this.payload)
                .catch(this.almacenarError)
                .finally(() => {
                    this.alternarCargando();
                    this.intentarIrAVerificacion();
                });
        },
        async iniciarSesionGoogle(){
            const proveedor = new firebase.auth.GoogleAuthProvider();
            proveedor.addScope('email');
            this.abrirPopoverConexion(proveedor);
        },
        // iniciarSesionFacebook(){
        //     const proveedor = new firebase.auth.FacebookAuthProvider();
        //     proveedor.addScope('email');
        //     this.abrirPopoverConexion(proveedor)
        // },
        async abrirPopoverConexion(proveedor){
            try {
                this.alternarCargando();
                this.removerErrores();

                const { credential: credenciales, user } = await firebase.auth().signInWithPopup(proveedor);

                if (!credenciales) return;

                const payload = {
                    provider: this.obtenerNombreProveedor(user),
                    token: credenciales.accessToken,
                    tipo: this.tipoLogin
                };

                await this.intentarIniciarSesionSocial(payload).catch(this.almacenarError);
                this.intentarIrAVerificacion();
            } catch (error){
                console.error(error);
            } finally {
                this.alternarCargando();
            }
        },
        obtenerNombreProveedor: (obj) => obj.providerData[0].providerId.split('.')[0],
        intentarIrAVerificacion(){
            if (this.existeErrorServidor || this.existeErrorSesion) return
            this.$router.push({ name: 'login.stop' })
        },
    }

}
</script>

<style lang="scss" scoped>
@media only screen and (min-device-width:320px) and (max-device-width:480px){
    .logo-box{
        display: none !important;
    }
}
@media only screen  and (max-width : 1224px) {
    .logo-box{
        display: none !important;
    }
}
.br-12{
    border-radius: 12px !important;
}
.alert-message{
    box-shadow: 0px 1px 4px #00000014;
    width: 338px;
    height: 138px;
    &::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 38px;
        bottom: auto;
        border: 25px solid;
        border-color: transparent transparent transparent white;
    }
}
.triangle{
    left: -36px;
    background-color: white;
    position: absolute;
    right: -20px;
    top: 6px;
    width: 0;
    height: 0;
    border-left: 30px solid #f0ad4e;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
}
.text-green{
    color: #28D07B !important;
}
</style>
